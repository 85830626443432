



















































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Seguridad extends Vue {
  private aNoticiasCategoria: Array<any> = [];
  private currentStartIndex = 0;
  private itemsPerPage = 4;

  private get visibleNoticias() {
    const itemsPerPage =
      this.$q.screen.xs || this.$q.screen.sm ? 1 : this.itemsPerPage;
    return this.aNoticiasCategoria.slice(
      this.currentStartIndex,
      this.currentStartIndex + itemsPerPage
    );
  }

  private nextNoticias() {
    const itemsPerPage =
      this.$q.screen.xs || this.$q.screen.sm ? 1 : this.itemsPerPage;
    if (
      this.currentStartIndex + itemsPerPage >=
      this.aNoticiasCategoria.length
    ) {
      this.currentStartIndex = 0;
    } else {
      this.currentStartIndex += itemsPerPage;
    }
  }

  private prevNoticias() {
    const itemsPerPage =
      this.$q.screen.xs || this.$q.screen.sm ? 1 : this.itemsPerPage;
    if (this.currentStartIndex === 0) {
      const remaining = this.aNoticiasCategoria.length % itemsPerPage;
      this.currentStartIndex =
        remaining === 0
          ? this.aNoticiasCategoria.length - itemsPerPage
          : this.aNoticiasCategoria.length - remaining;
    } else {
      this.currentStartIndex -= itemsPerPage;
    }
  }

  private mounted() {
    this.getInfoNoticiaCategoria();
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);
    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return (
        "Publicado el " + diaInicio + " de " + mesInicio + ", " + anhoInicio
      );
    }
  }

  private getInfoNoticiaCategoria() {
    this.$axios
      .get("noticias/categoria2?nombre_web=seguridad")
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aNoticiasCategoria = res.data.noticias;

        for (let i = 0; i < this.aNoticiasCategoria.length; i++) {
          // Verifica si existe la imagen completa
          if (
            this.aNoticiasCategoria[i].fotos &&
            this.aNoticiasCategoria[i].fotos.image_fulltext &&
            this.aNoticiasCategoria[i].fotos.image_fulltext.trim() !== ""
          ) {
            this.aNoticiasCategoria[i].fotos.image_fulltext =
              base + this.aNoticiasCategoria[i].fotos.image_fulltext;
          } else {
            // imagen por defecto
            this.aNoticiasCategoria[i].fotos = {
              image_intro: require("@/assets/logos/logo300.webp"),
              image_fulltext: require("@/assets/logos/logo300.webp"),
            };
          }
        }

        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.aNoticiasCategoria = [];

        this.$q.loading.hide();
        console.log(err);
      });
  }

  private irNoticia(nombreWeb: any) {
    if (nombreWeb && nombreWeb != "") {
      if (
        this.$router.currentRoute.name == "Publicacion" &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombreWeb
      ) {
        this.$store.commit("SET_MODAL_BUSCADOR", false);
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == "Publicacion") {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombreWeb
          ) {
            this.$store.commit("SET_MODAL_BUSCADOR", false);
            this.$router
              .push({ name: "Publicacion", params: { nombre: nombreWeb } })
              .catch((err) => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$store.commit("SET_MODAL_BUSCADOR", false);
            this.$router
              .push({ name: "Publicacion", params: { nombre: nombreWeb } })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          this.$store.commit("SET_MODAL_BUSCADOR", false);
          this.$router
            .push({ name: "Publicacion", params: { nombre: nombreWeb } })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
